<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-center-center-layout>

        <template #default>

            <div class="page-layout">

                <!-- logo -->
                <div class="mb-10 text-center text-primary-600 text-5xl tracking-wider">
                    Habile
                </div>

                <!-- patient search field -->
                <div class="lsn-field-wrapper">
                    <label class="lsn-label">Choix d'un patient</label>
                    <div class="relative">
                        <input v-model="patientQuery" type="text" class="lsn-input" @focus="patientSearchFieldFocused = true" @blur="onBlurPatientSearchField" />
                        <div v-if="patientQuery.length" class="lsn-empty-btn" @click="unselectPatient">
                            <svg-icon type="mdi" :path="mdiClose" size="36"></svg-icon>
                        </div>
                    </div>
                    <div v-if="patientQuery && patientSearchFieldFocused" class="search-result__list">
                        <template v-if="patientQuery.length < 3">
                            <div class="search-result__minimum-query">
                                minimum 3 characters
                            </div>
                        </template>
                        <template v-else-if="patients.length">
                            <div v-for="patient in patients" :key="patient.hid" class="search-result__item" @click="selectPatient(patient)">
                                <div class="patient__fullname">
                                    {{ patient.first_name }} {{ patient.last_name }}
                                </div>
                                <div class="patient__birthdate">
                                    {{ getFormattedDate(patient.birthdate) }}
                                </div>
                                <div class="patient__eid">
                                    {{ patient.eid }}
                                </div>
                            </div>
                        </template>
                        <template v-else-if="patientSearching">
                            <div class="search-result__searching">
                                recherche en cours
                            </div>
                        </template>
                        <template v-else>
                            <div class="search-result__none">
                                there is no patient found
                            </div>
                        </template>
                    </div>
                </div>

                <!-- new patient button -->
                <template v-if="!selectedPatient">
                    <div class="text-primary-600 text-center text-xl">
                        {{ $t('common.or') }}
                    </div>

                    <!-- button -->
                    <div class="flex flex-wrap justify-center mt-4">
                        <button class="lsn-btn lsn-btn--primary" @click="newPatient">
                            Nouveau patient
                        </button>
                    </div>
                </template>

                <!-- consents list -->
                <div class="h-[400px]">
                    <template v-if="selectedPatient && consents.length">
                        <div class="block mb-2 pl-6 text-primary-600">
                            Consentements existants <span class="ml-1">( {{ consents.length }} )</span>
                        </div>
                        <perfect-scrollbar>
                            <div v-for="cConsent in consents" :key="cConsent.hid" class="consent-item" @click="previewConsent(cConsent)">
                                <div class="flex-grow">
                                    <div class="consent-item__name">
                                        {{ cConsent.pdf_document.name }}
                                    </div>
                                    <div class="consent-item__version">
                                        version {{ cConsent.pdf_document.version }}
                                    </div>
                                </div>
                                <div class="consent-item__created_at">
                                    {{ getFormattedDate(cConsent.created_at) }}
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </template>
                    <template v-else-if="selectedPatient && consents.length == 0">
                        <div class="px-5 text-center text-primary-900 italic">
                            aucun consentement pour ce patient
                        </div>
                    </template>
                </div>

                <!-- button -->
                <div class="mt-12 flex flex-wrap justify-center">
                    <button v-if="newConsentBtn" class="lsn-btn lsn-btn--primary" @click="newConsent">
                        Nouveau consentement
                    </button>
                </div>

            </div>

        </template>

        <!-- footer -->
        <template #lsn-footer>
            <div class="flex items-center justify-center h-full px-4">
                <!-- copyright -->
                <div class="text-xs text-gray-500 font-thin">
                    &copy; {{ year }} - Habile Sàrl - {{ $t('footer.all_rights_reserved') }}
                </div>
                <!-- version -->
                <div class="ml-auto text-xs text-gray-500 font-thin">
                    v.{{ appVersion }}
                </div>
            </div>
        </template>

    </lsn-center-center-layout>

    <!-- consent PDF preview -->
    <lsn-side-panel side="right" :is-open="isConsentPreviewOpen" width="800px">
        <lsn-pdf-preview :url="consentPdfUrl(route.query['file-hid'])">
            <template #lsn-toolbar>
                <div class="ml-auto lsn-toolbar-btn" @click="router.replace({ query: {} })">
                    <svg-icon type="mdi" :path="mdiClose" size="36"></svg-icon>
                </div>
            </template>
        </lsn-pdf-preview>
    </lsn-side-panel>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Welcome',
};

</script>

<script setup>

import moment from 'moment';
import { mdiClose } from '@mdi/js';
import { delay } from "lodash-es";
import { storeToRefs } from 'pinia';
import { ref, computed, watch, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { usePatientsStore } from '@/stores/patients';
import { useConsentsStore } from '@/stores/consents';

const router = useRouter();
const route = useRoute();

const patientsStore = usePatientsStore();
const { patients } = storeToRefs(patientsStore);

const consensStore = useConsentsStore();
const { consents } = storeToRefs(consensStore);

const appVersion = import.meta.env.VITE_VERSION;
const year = new Date().getFullYear();

const patientSearchFieldFocused = ref(false);
const patientSearching = ref(false);

const patientQuery = ref('');

const selectedPatient = ref(null);

var debounceIntervalleId;

const newConsentBtn = computed(() =>
{
    return selectedPatient.value;
});

const isConsentPreviewOpen = computed(() =>
{
    return ['left', 'right'].includes(route.query['side-panel-open']);
});

watch(patientQuery, (newPatientQuery) =>
{
    clearTimeout(debounceIntervalleId);

    selectedPatient.value = null;

    if(newPatientQuery.length > 2)
    {
        patientSearching.value = true;

        debounceIntervalleId = setTimeout(() =>
        {
            patientsStore.search(newPatientQuery).finally(() =>
            {
                patientSearching.value = false;
            });
        }, 300);
    }
    else
    {
        patientsStore.patients = [];
    }
});

watch(selectedPatient, (newSelectedPatient) =>
{
    consents.value = [];

    if(newSelectedPatient)
    {
        consensStore.fetchByPatient(newSelectedPatient.hid);
    }
});

function initialize()
{

}

function consentPdfUrl(fileHid)
{
    return window.location.origin + '/file/view/' + fileHid;
}

function getFormattedDate(date)
{
    return moment(date).format("DD.MM.YYYY");
}

function selectPatient(patient)
{
    patientQuery.value = patient.first_name + ' ' + patient.last_name;

    nextTick(() =>
    {
        selectedPatient.value = patient;
    });
}

function unselectPatient()
{
    selectedPatient.value = null;
    patientQuery.value = '';
}

function onBlurPatientSearchField()
{
    delay(() =>
    {
        patientSearchFieldFocused.value = false;
    }, 100);
}

function newConsent()
{
    router.push({
        name:   'consent-form',
        params: {
            patient_hid: selectedPatient.value.hid,
        },
    });
}

function newPatient()
{
    router.push({
        name: 'consent-form',
    });
}

function previewConsent(consent)
{
    router.replace({ query: { 'side-panel-open': 'right', 'file-hid': consent.hid } });
}

initialize();

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.page-layout
{
    width: 400px;
    height: 750px;
    @apply mx-auto px-4 flex flex-col;
}

.lsn-field-wrapper
{
    @apply relative p-4;
}

.lsn-label
{
    @apply block mb-2 pl-2 text-primary-600;
}

.lsn-input
{
    height: 48px;
    @apply px-3 rounded-lg w-full outline-primary-500 border-2 border-primary-600 tracking-wider text-center text-primary-900 text-lg;
}

.lsn-empty-btn
{
    margin-top: -18px;
    @apply absolute right-0 top-1/2 mr-2 text-primary-600;
}

.lsn-selector
{
    height: 48px;
    background-repeat: no-repeat;
    background-color: white;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQUlEQVQoU2NkIBIwoqubs3j9/5TYQAxxFAGQIphGdMVwhciKsCkGK8SmCF0xhltw+Y10E4lyI8w6onyNrBhbOAIAkkIgC5kIYPcAAAAASUVORK5CYII=");
    background-position: right 0.5rem center;
    @apply appearance-none px-3 rounded-lg w-full outline-primary-500 border-2 border-primary-600 tracking-wider text-center text-primary-900 text-lg;
}

.lsn-btn
{
    height: 48px;
    @apply px-10 rounded-lg tracking-wider text-center text-lg border-0;
}

.lsn-btn--primary
{
    @apply bg-primary-600 text-white;
}

.lsn-btn--disable
{
    @apply bg-gray-50 text-gray-300 border border-gray-300;
}

.search-result__list
{
    width: 500px;
    height: 500px;
    left: -60px;
    @apply absolute z-10 pt-5 shadow-lg overflow-hidden bg-white;
}

.search-result__item
{
    width: 500px;
    height: 48px;
    @apply flex items-center px-5 bg-white cursor-pointer hover:bg-primary-100;
}

.search-result__none, .search-result__minimum-query, .search-result__searching
{
    width: 500px;
    height: 48px;
    @apply flex items-center justify-center px-5 bg-white text-primary-900 italic;
}

.patient__fullname
{
    @apply text-primary-900;
}

.patient__birthdate
{
    @apply ml-auto flex-none w-24 text-center text-primary-600;
}

.patient__eid
{
    @apply flex-none w-24 text-right text-primary-600;
}

.ps
{
    height: 400px;
}

.consent-item
{
    @apply flex flex-wrap items-center py-4 px-6 mb-2 bg-primary-100 rounded-xl cursor-pointer hover:bg-primary-200;
}

.consent-item__name
{
    @apply text-primary-700 mb-1;
}

.consent-item__version
{
    @apply ml-auto text-primary-500;
}

.consent-item__created_at
{
    @apply text-primary-700;
}

</style>
