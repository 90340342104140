//------------------------------------------------------------------------------ VUE

import { createApp } from 'vue';

//------------------------------------------------------------------------------ APP CONSTANTS

import constants from "./constants";

//------------------------------------------------------------------------------ STYLE

import './assets/styles/main.scss';

//------------------------------------------------------------------------------ ICONS

import SvgIcon from '@jamescoyle/vue-icon';

//------------------------------------------------------------------------------ I18N

import { setupI18n } from './i18n';

const i18n = setupI18n();

//------------------------------------------------------------------------------ PINIA

import { createPinia } from 'pinia';

const pinia = createPinia();

//------------------------------------------------------------------------------ ROUTER

import { setupRouter } from './router';

const router = setupRouter();

//------------------------------------------------------------------------------ PLUGINS

import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

import Toast from "vue-toastification";

//------------------------------------------------------------------------------ UTILS



//------------------------------------------------------------------------------ COMPONENTS & DIRECTIVE

import LsnDateInput from './components/LsnDateInput';
import LsnTextInput from './components/LsnTextInput';

import {
    LsnCenterCenterLayout,
    LsnClickOutside,
    LsnPdfPreview,
    LsnSidePanel,
    LsnSimpleLayout,
    LsnSwitch,
} from 'lausanne';

//------------------------------------------------------------------------------ APP

import App from './App';

//------------------------------------------------------------------------------ APP BUILDING

const app = createApp(App);

// Register global constants as `$const`
app.config.globalProperties.$const = constants;

app.use(pinia)
    .use(i18n)
    .use(router)
    .use(PerfectScrollbar)
    .use(Toast, { hideProgressBar: true, timeout: 5000, position: "bottom-right" })
    .directive('lsn-click-outside', LsnClickOutside)
    .component('LsnCenterCenterLayout', LsnCenterCenterLayout)
    .component('LsnPdfPreview', LsnPdfPreview)
    .component('LsnSidePanel', LsnSidePanel)
    .component('LsnSimpleLayout', LsnSimpleLayout)
    .component('LsnSwitch', LsnSwitch)
    .component('LsnTextInput', LsnTextInput)
    .component('LsnDateInput', LsnDateInput)
    .component('SvgIcon', SvgIcon);

import { useAuthenticationStore } from '@/stores/authentication';

const authenticationStore = useAuthenticationStore();

authenticationStore.check().finally((response) =>
{
    router.isReady().then(() =>
    {
        app.mount('#app');
    });
});
