import { createI18n } from 'vue-i18n';
import axios          from '@/axios';

export const SUPPORTED_LOCALES = ['fr', 'de', 'it', 'en'];

/** @var i18n The global Vue-i18n instance. */
let i18n = null;

export function setupI18n()
{
    if(i18n !== null)
    {
        throw new Error('The i18n object was already set up');
    }

    const localeRegex = new RegExp('^\/consent\/[a-zA-Z]{2}', 'g');

    if(!localeRegex.test(window.location.pathname))
    {
        window.history.replaceState({}, "", window.location.origin + '/consent/fr');
    }

    const localePattern = /\/consent\/([a-zA-Z]{2})/g;
    let locale = localePattern.exec(window.location.pathname)[1].toLowerCase();

    if(!SUPPORTED_LOCALES.includes(locale))
    {
        locale = 'fr';
    }

    i18n = createI18n({
        globalInjection: true,
        legacy:          false,
        locale:          locale,
        fallbackLocale:  'en',
    });

    setI18nLanguage(locale);
    loadMessages(locale);

    return i18n;
};

export function setI18nLanguage(locale)
{
    const i18n = getInstance();

    if(i18n.global)
    {
        if(i18n.mode === 'legacy')
        {
            i18n.global.locale = locale;
        }
        else
        {
            i18n.global.locale.value = locale;
        }
    }
    else
    {
        i18n.locale = locale;
    }

    axios.defaults.headers.common['Accept-Language'] = locale;

    document.querySelector('html').setAttribute('lang', locale);
};

export async function loadMessages(locale)
{
    const i18n = getInstance();

    // Load translations for selected locale
    const response = await axios.get(`/translations/${locale}.json`, {
        headers:
        {
            'Cache-Control': 'no-cache',
            'Pragma':        'no-cache',
            'Expires':       '0',
        },
        baseURL: window.location.origin, // translation are loaded from original URL (not api url)
    });

    i18n.global.setLocaleMessage(locale, response.data);
};

/**
 * Get the global i18n instance.
 *
 * @returns {{}} The `i18n` object.
 * @throws {TypeError} If the i18n object hasn't been initialized with `setupI18n()`, yet.
 */
export function getInstance()
{
    if(i18n === null)
    {
        throw new TypeError('The i18n object has not been set up, yet');
    }

    return i18n;
};
