<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-center-center-layout>

        <template #default>

            <div class="absolute top-5 left-5 text-4xl text-primary-600 tracking-wider">
                Habile
            </div>

            <div class="page-layout">

                <div class="mt-12 mb-8 text-primary-400 text-5xl text-center">
                    Merci !
                </div>

                <div class="mb-8 text-gray-500 text-lg font-thin text-center">
                    Le consentement signé a bien été enregistré.
                </div>

                <!-- <div class="mb-8 mx-auto flex justify-center">

                    <div class="lsn-btn lsn-btn--primary py-5 mr-2" @click="showSideMailPreview = true">
                        envoyer par e-mail
                    </div>

                </div> -->

                <div class="mx-auto flex justify-center mt-60">

                    <button class="lsn-btn lsn-btn--primary" @click="goToWelcome">
                        nouveau consentement
                    </button>

                </div>

            </div>

        </template>

        <template #lsn-footer>
            <div class="flex items-center justify-center h-full px-4">
                <!-- copyright -->
                <div class="text-xs text-gray-500 font-thin">
                    &copy; {{ year }} - Habile Sàrl - {{ $t('footer.all_rights_reserved') }}
                </div>
                <!-- version -->
                <div class="ml-auto text-xs text-gray-500 font-thin">
                    v.{{ appVersion }}
                </div>
            </div>
        </template>

    </lsn-center-center-layout>

    <!-- side mail preview -->
    <!-- <lsn-side-panel side="right" :is-open="showSideMailPreview" width="500px">
        <side-mail-preview
            :patient-id="parseInt($route.params.patient_id)"
            :doctor-id="parseInt($route.params.doctor_id)"
            :file-id="parseInt($route.params.file_id)"
            code="CONSENT"
            @close="showSideMailPreview = false"
        />
    </lsn-side-panel> -->

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Confirmation',
};

</script>

<script setup>

import { useI18n } from "vue-i18n";

const appVersion = import.meta.env.VITE_VERSION;
const year = new Date().getFullYear();
const i18n = useI18n();

function goToWelcome()
{
    location.replace(window.location.origin + '/consent/' + i18n.locale.value);
}

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.page-layout
{
    max-width: 400px;
    @apply mx-auto px-4;
}

.lsn-btn
{
    height: 48px;
    @apply px-20 rounded-lg tracking-wider text-center text-lg border-0;
}

.lsn-btn--primary
{
    @apply bg-primary-600 text-white;
}

</style>
