<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="overlay">

        <div class="logo-wrapper">
            <div class="text-primary-600 text-center font-light">
                Authentification
            </div>
        </div>

        <div class="">
            <div class="code-input-wrapper">
                <form>
                    <input ref="code_input" v-model="code" class="code-input" type="password" pattern="[0-9]*" autocomplete="off" />
                </form>
            </div>

            <div class="keyboard" :class="{'keyboard--disabled': authenticating}">
                <div class="keyboard-button" @click="addDigitToCode(1)">
                    1
                </div>
                <div class="keyboard-button" @click="addDigitToCode(2)">
                    2
                </div>
                <div class="keyboard-button" @click="addDigitToCode(3)">
                    3
                </div>
                <div class="keyboard-button" @click="addDigitToCode(4)">
                    4
                </div>
                <div class="keyboard-button" @click="addDigitToCode(5)">
                    5
                </div>
                <div class="keyboard-button" @click="addDigitToCode(6)">
                    6
                </div>
                <div class="keyboard-button" @click="addDigitToCode(7)">
                    7
                </div>
                <div class="keyboard-button" @click="addDigitToCode(8)">
                    8
                </div>
                <div class="keyboard-button" @click="addDigitToCode(9)">
                    9
                </div>
                <div class="keyboard-placeholder">

                </div>
                <div class="keyboard-button" @click="addDigitToCode(0)">
                    0
                </div>
                <div class="keyboard-button-icon" @click="erase">
                    <svg-icon type="mdi" :path="mdiBackspaceOutline" size="60"></svg-icon>
                </div>
            </div>
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Pin',
};

</script>

<script setup>

import { ref, onMounted, watch, nextTick } from 'vue';
import { mdiBackspaceOutline } from '@mdi/js';
import { useAuthenticationStore } from '@/stores/authentication';

const authentication = useAuthenticationStore();
const authenticating = ref(false);
const code = ref('');
const code_input = ref(null);

onMounted(() =>
{
    let isTouch =  ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );

    // Only on non-touch devices.
    if(!isTouch)
    {
        nextTick(() =>
        {
            code_input.value.focus();
        });
    }
});

watch(code, (newCode) =>
{
    if(newCode.length == 6)
    {
        authenticating.value = true;

        authentication.unlock(newCode).catch(() =>
        {
            code.value = '';
        }).finally(() =>
        {
            authenticating.value = false;
        });
    }
});

function addDigitToCode(digit)
{
    code.value += digit;
}

function erase()
{
    if(code.value)
    {
        code.value = code.value.slice(0, -1);
    }
}

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.overlay
{
    background-image: url('/img/habile_bg_waves_green.svg');
    background-position: bottom;
    background-repeat: no-repeat;
    @apply absolute inset-0 z-50 flex flex-wrap items-center justify-center;
}

.logo-wrapper
{
    top: 16px;
    left: 0px;
    width: 150px;
    @apply absolute text-center;
}

.keyboard
{
    @apply grid grid-cols-3 gap-5;
}

.code-input-wrapper
{
    @apply flex justify-center mb-10;
}

.code-input
{
    letter-spacing: 0.3rem;
    @apply py-2 border-2 border-primary-600 rounded-sm text-center outline-none text-2xl text-primary-600;
}

.keyboard-button
{
    height: 100px;
    width: 100px;
    @apply flex items-center justify-center border-2 border-primary-600 rounded-full text-3xl text-primary-600 cursor-pointer select-none;
}

.keyboard-button-icon
{
    height: 100px;
    width: 100px;
    @apply flex items-center justify-center cursor-pointer select-none text-primary-600;
}

.keyboard-button:hover
{
    @apply bg-primary-200;
}

.keyboard--disabled .keyboard-button
{
    @apply border-gray-300 text-gray-300;
}

.keyboard-placeholder
{
    height: 100px;
    width: 100px;
}

</style>
