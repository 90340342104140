<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-center-center-layout>

        <template #lsn-top-bar>
            <div class="flex items-center h-full">
                <div class="mx-4 text-primary-600 text-xl cursor-pointer" @click="backToConsentForm">
                    &larr; Précédent
                </div>
            </div>
        </template>

        <template #default>

            <div v-if="!loading" class="page-layout">

                <!-- logo -->
                <div class="mb-10 text-center text-primary-600 text-5xl tracking-wider">
                    Habile
                </div>

                <!-- line 1 -->
                <div class="form-line">

                    <!-- eid -->
                    <lsn-text-input
                        v-model="patient.eid"
                        :label="$t('form.patient.eid.label')"
                        :error="errors.eid"
                        class=""
                    />

                </div>

                <!-- line 2 -->
                <div class="form-line">

                    <!-- first name -->
                    <lsn-text-input
                        v-model="patient.first_name"
                        :label="$t('form.patient.first_name.label')"
                        :required="true"
                        :error="errors.first_name"
                    />

                    <!-- last name -->
                    <lsn-text-input
                        v-model="patient.last_name"
                        :label="$t('form.patient.last_name.label')"
                        :required="true"
                        :error="errors.last_name"
                    />

                </div>

                <!-- line 3 -->
                <div class="form-line">

                    <!-- birthdate -->
                    <lsn-date-input
                        v-model="patient.birthdate"
                        :label="$t('form.patient.birthdate.label')"
                        :required="true"
                        :error="errors.birthdate"
                    />

                </div>

                <!-- line 4 -->
                <div class="form-line">

                    <!-- gender -->
                    <div class="lsn-field-wrapper">
                        <label class="lsn-label">{{ $t('form.patient.gender.label') }} *</label>
                        <select v-model="patient.gender" class="lsn-selector">
                            <option value="">
                                {{ $t('common.please_choose') }}
                            </option>
                            <option value="F">
                                {{ $t('form.patient.gender.options.female.label') }}
                            </option>
                            <option value="M">
                                {{ $t('form.patient.gender.options.male.label') }}
                            </option>
                        </select>
                    </div>

                </div>

                <!-- line 5 -->
                <div class="form-line">

                    <!-- email -->
                    <lsn-text-input
                        v-model="patient.email"
                        :label="$t('form.patient.email.label')"
                        :error="errors.email"
                        class="w-full"
                    />

                </div>

                <!-- line 6 -->
                <div class="form-line">

                    <!-- mobile phone -->
                    <lsn-text-input
                        v-model="patient.mobile_phone"
                        :label="$t('form.patient.mobile_phone.label')"
                        :error="errors.mobile_phone"
                    />

                </div>

                <!-- button -->
                <div class="mt-12 flex flex-wrap justify-center">
                    <button v-if="nextable" class="lsn-btn lsn-btn--primary" @click="next">
                        {{ $t('common.next') }}
                    </button>
                    <div v-else class="lsn-btn lsn-btn--disable">
                        {{ $t('common.next') }}
                    </div>
                </div>

            </div>

        </template>

        <template #lsn-footer>
            <div class="flex items-center justify-center h-full px-4">
                <!-- copyright -->
                <div class="text-xs text-gray-500 font-thin">
                    &copy; {{ year }} - Habile Sàrl - {{ $t('footer.all_rights_reserved') }}
                </div>
                <!-- version -->
                <div class="ml-auto text-xs text-gray-500 font-thin">
                    v.{{ appVersion }}
                </div>
            </div>
        </template>

    </lsn-center-center-layout>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>

import { clone } from "lodash-es";
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { usePatientsStore } from '@/stores/patients';
import LsnTextInput from '../components/LsnTextInput.vue';

const router = useRouter();
const route = useRoute();

const patientStore = usePatientsStore();
const patient = ref({});
const errors = ref({});

const appVersion = import.meta.env.VITE_VERSION;
const year = new Date().getFullYear();

const loading = ref(false);

const nextable = computed(() =>
{
    return true;
});

function initialize()
{
    loading.value = true;

    // If a patient hid was given in URL.
    if(route.params.patient_hid)
    {
        // Try to fetch patient by HID
        patientStore.fetchByHid(route.params.patient_hid).then(() =>
        {
            patient.value = clone(patientStore.patient);
        }).catch((err) =>
        {
            // There is no patient with given HID => new patient
            setNewPatient();
        }).finally(() =>
        {
            loading.value = false;
        });
    }
    else // No patient HID was given in URL => new patient
    {
        setNewPatient();
        loading.value = false;
    }
}

function setNewPatient()
{
    patient.value = {
        first_name:   null,
        last_name:    null,
        birthdate:    null,
        gender:       null,
        email:        null,
        mobile_phone: null,
    };
}

function next()
{
    errors.value = {};

    if(!patient.value.hid)
    {
        createPatient();
    }
    else
    {
        updatePatient();
    }
}

function createPatient()
{
    patientStore.create(patient.value).then(() =>
    {
        let routeParams = route.params;
        routeParams.patient_hid = patientStore.patient.hid;

        router.push({
            name:   'consent-preview',
            params: routeParams,
        });
    }).catch((err) =>
    {
        errors.value = err.response.errors;
    });
}

function updatePatient()
{
    patientStore.update(patient.value).then(() =>
    {
        let routeParams = route.params;
        routeParams.patient_hid = patientStore.patient.hid;

        router.push({
            name:   'consent-preview',
            params: routeParams,
        });
    }).catch((err) =>
    {
        errors.value = err.response.errors;
    });
}

function backToConsentForm()
{
    router.push({
        name:   'consent-form',
        params: route.params,
        query:  {
            pdf_document_hid: route.params.pdf_document_hid,
            practitioner_hid: route.params.practitioner_hid,
            language:         route.params.language,
        },
    });
}

initialize();

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.page-layout
{
    width: 500px;
    @apply mx-auto px-4;
}

.form-line
{
    @apply flex;
}

.lsn-field-wrapper
{
    @apply relative p-4;
}

.lsn-label
{
    @apply block mb-2 pl-2 text-primary-600;
}

.lsn-selector
{
    height: 48px;
    background-repeat: no-repeat;
    background-color: white;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQUlEQVQoU2NkIBIwoqubs3j9/5TYQAxxFAGQIphGdMVwhciKsCkGK8SmCF0xhltw+Y10E4lyI8w6onyNrBhbOAIAkkIgC5kIYPcAAAAASUVORK5CYII=");
    background-position: right 0.5rem center;
    @apply appearance-none px-3 rounded-lg w-full outline-primary-500 border-2 border-primary-600 tracking-wider text-center text-primary-900 text-lg;
}

.lsn-btn
{
    height: 48px;
    @apply px-20 rounded-lg tracking-wider text-center text-lg border-0;
}

.lsn-btn--primary
{
    @apply bg-primary-600 text-white;
}

.lsn-btn--disable
{
    @apply bg-gray-50 text-gray-300 border border-gray-300;
}

</style>
