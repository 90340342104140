/**********
 * Router *
 *********/

import { createWebHistory, createRouter } from 'vue-router';
import routes from './routes';

export function setupRouter()
{
    //-------------------------------------------------------------------------- CONSTRUCTOR

    const router = createRouter({
        history: createWebHistory(),
        base:    import.meta.env.VITE_CONSENT_APP_URL,
        routes,
    });

    //--------------------------------------------------------------------------

    return router;
}
