/* ==============
 * Patients Store
 * ==============
 */

import { defineStore } from 'pinia';
import axios from '@/axios';

export const usePatientsStore = defineStore('patients', {

    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            patients: [],
            patient:  null,
        };
    },

    // ------------------------------------------------------------------------- ACTIONS

    actions:
    {
        search(patientQuery)
        {
            const url = encodeURI(('/v1/patient/search/:query').replace(':query', patientQuery));

            return axios.get(url, {
                withCredentials: true,
            }).then((response) =>
            {
                this.patients = response.data;

                return Promise.resolve(response);
            });
        },

        fetchByHid(hid)
        {
            const url = ('/v1/patient/:hid').replace(':hid', hid);

            return axios.get(url, {
                withCredentials: true,
            }).then((response) =>
            {
                this.patient = response.data;

                return Promise.resolve(response);
            });
        },

        create(patient)
        {
            return axios.post('/v1/patient', patient, {
                withCredentials: true,
            }).then((response) =>
            {
                this.patient = response.data;

                return Promise.resolve(response);
            });
        },

        update(patient)
        {
            const url = ('/v1/patient/:hid').replace(':hid', patient.hid);

            return axios.put(url, patient, {
                withCredentials: true,
            }).then((response) =>
            {
                this.patient = response.data;

                return Promise.resolve(response);
            });
        },
    },
});
