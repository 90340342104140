<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-center-center-layout>

        <template #lsn-top-bar>
            <div class="flex items-center h-full">
                <div class="mx-4 text-primary-600 text-xl cursor-pointer" @click="backToWelcome">
                    &larr; Précédent
                </div>
            </div>
        </template>

        <template #default>

            <div v-if="!loading" class="page-layout">

                <!-- logo -->
                <div class="mb-10 text-center text-primary-600 text-5xl tracking-wider">
                    Habile
                </div>

                <div class="my-5 text-primary-600 text-center text-3xl">
                    Nouveau consentement
                </div>

                <!-- patient -->
                <div v-if="patient" class="patient">
                    <div>
                        <div class="patient__fullname">
                            {{ patient.first_name }} {{ patient.last_name }}
                        </div>
                        <div class="patient__eid">
                            {{ patient.eid }}
                        </div>
                    </div>
                    <div class="patient__birthdate">
                        {{ patient.birthdate }}
                    </div>
                </div>
                <div v-else class="flex items-center px-4 py-4 mx-4 mb-4 bg-primary-100 rounded-xl text-primary-600">
                    La prochaine étape présentera le formulaire permettant d'ajouter un patient.
                </div>

                <!-- practitioner selector -->
                <div class="lsn-field-wrapper">
                    <label class="lsn-label">{{ $t('form.practitioner.label') }}</label>
                    <select v-model="practitionerHid" class="lsn-selector">
                        <option value="">
                            {{ $t('common.please_choose') }}
                        </option>
                        <option v-for="practitioner in practitioners" :key="practitioner.hid" :value="practitioner.hid">
                            {{ practitioner.title }} {{ practitioner.first_name }} {{ practitioner.last_name }}
                        </option>
                    </select>
                </div>

                <!-- consent selector -->
                <div class="lsn-field-wrapper">
                    <label class="lsn-label">{{ $t('form.consent.label') }}</label>
                    <select v-model="pdfDocumentHid" class="lsn-selector">
                        <option value="">
                            {{ $t('common.please_choose') }}
                        </option>
                        <option v-for="pdfDocument in pdfDocuments" :key="pdfDocument.hid" :value="pdfDocument.hid">
                            {{ pdfDocument.name }}
                        </option>
                    </select>
                </div>

                <!-- langue -->
                <div class="lsn-field-wrapper">
                    <label class="lsn-label">{{ $t('form.language.label') }}</label>
                    <select v-model="language" class="lsn-selector">
                        <option value="">
                            {{ $t('common.please_choose') }}
                        </option>
                        <option value="fr">
                            {{ $t('form.language.options.french') }}
                        </option>
                        <option value="de">
                            {{ $t('form.language.options.german') }}
                        </option>
                        <option value="it">
                            {{ $t('form.language.options.italian') }}
                        </option>
                        <option value="en">
                            {{ $t('form.language.options.english') }}
                        </option>
                    </select>
                </div>

                <!-- button -->
                <div class="mt-12 flex flex-wrap justify-center">
                    <button v-if="nextable" class="lsn-btn lsn-btn--primary" @click="next">
                        {{ $t('common.next') }}
                    </button>
                    <div v-else class="lsn-btn lsn-btn--disable">
                        {{ $t('common.next') }}
                    </div>
                </div>

            </div>

        </template>

        <template #lsn-footer>
            <div class="flex items-center justify-center h-full px-4">
                <!-- copyright -->
                <div class="text-xs text-gray-500 font-thin">
                    &copy; {{ year }} - Habile Sàrl - {{ $t('footer.all_rights_reserved') }}
                </div>
                <!-- version -->
                <div class="ml-auto text-xs text-gray-500 font-thin">
                    v.{{ appVersion }}
                </div>
            </div>
        </template>

    </lsn-center-center-layout>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Welcome',
};

</script>

<script setup>

import moment from 'moment';
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { usePatientsStore } from '@/stores/patients';
import { usePdfDocumentsStore } from '@/stores/pdfDocuments';
import { useUsersStore } from '@/stores/users';

const router = useRouter();
const route = useRoute();

const patientsStore = usePatientsStore();
const usersStore = useUsersStore();
const pdfDocumentsStore = usePdfDocumentsStore();
const { patient } = storeToRefs(patientsStore);
const { pdfDocuments } = storeToRefs(pdfDocumentsStore);
const { practitioners } = storeToRefs(usersStore);

const appVersion = import.meta.env.VITE_VERSION;
const year = new Date().getFullYear();

const loading = ref(false);

const practitionerHid = ref('');
const pdfDocumentHid = ref('');
const language = ref('');

const nextable = computed(() =>
{
    return  practitionerHid.value && pdfDocumentHid.value && language.value;
});

function initialize()
{
    loading.value = true;

    const promises = [];
    promises.push(usersStore.fetchPractitioners());
    promises.push(pdfDocumentsStore.fetch());

    if(route.params.patient_hid)
    {
        patientsStore.fetchByHid(route.params.patient_hid);
    }

    Promise.all(promises).then(() =>
    {
        // Set value from URL query params (if given)
        pdfDocumentHid.value = route.query.pdf_document_hid ?? '';
        practitionerHid.value = route.query.practitioner_hid ?? '';
        language.value = route.query.language ?? '';

        loading.value = false;
    });
}

function next()
{
    router.push({
        name:   'patient-form',
        params: {
            pdf_document_hid: pdfDocumentHid.value,
            practitioner_hid: practitionerHid.value,
            language:         language.value,
            patient_hid:      route.params.patient_hid,
        },
    });
}

function backToWelcome()
{
    router.push({
        name: 'welcome',
    });
}

initialize();

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.page-layout
{
    width: 400px;
    height: 750px;
    @apply mx-auto px-4 flex flex-col;
}

.lsn-field-wrapper
{
    @apply relative p-4;
}

.lsn-label
{
    @apply block mb-2 pl-2 text-primary-600;
}

.lsn-selector
{
    height: 48px;
    background-repeat: no-repeat;
    background-color: white;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQUlEQVQoU2NkIBIwoqubs3j9/5TYQAxxFAGQIphGdMVwhciKsCkGK8SmCF0xhltw+Y10E4lyI8w6onyNrBhbOAIAkkIgC5kIYPcAAAAASUVORK5CYII=");
    background-position: right 0.5rem center;
    @apply appearance-none px-3 rounded-lg w-full outline-primary-500 border-2 border-primary-600 tracking-wider text-center text-primary-900 text-lg;
}

.lsn-btn
{
    height: 48px;
    @apply px-20 rounded-lg tracking-wider text-center text-lg border-0;
}

.lsn-btn--primary
{
    @apply bg-primary-600 text-white;
}

.lsn-btn--disable
{
    @apply bg-gray-50 text-gray-300 border border-gray-300;
}

.patient
{
    @apply flex items-center px-4 py-2 mx-4 mb-4 bg-primary-100 rounded-xl;
}

.patient__fullname
{
    @apply text-primary-900 my-1;
}

.patient__eid
{
    @apply text-primary-500 mb-1;
}

.patient__eid:empty
{
    @apply hidden;
}

.patient__birthdate
{
    @apply ml-auto flex-none w-24 text-right text-primary-600;
}

</style>
