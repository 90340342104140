/* ===========
 * Users Store
 * ===========
 */

import { defineStore } from 'pinia';
import axios from '@/axios';

export const useUsersStore = defineStore('users', {

    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            cUser:         null,
            practitioners: [],
        };
    },

    // ------------------------------------------------------------------------- ACTIONS

    actions:
    {
        fetchPractitioners()
        {
            const url = encodeURI(('/v1/user/roles/:roles').replace(':roles', 2));

            return axios.get(url, {
                withCredentials: true,
            }).then((response) =>
            {
                this.practitioners = response.data;

                return Promise.resolve(response);
            });
        },
    },
});
