<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="lsn-form__wrapper">

        <!-- label -->
        <label v-if="label" class="lsn-form__label">
            {{ label }}
            <template v-if="required">
                &nbsp;*
            </template>
        </label>

        <!-- input -->
        <input
            type="text" class="lsn-form__input"
            :value="modelValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            :autocomplete="autocomplete"
            :autocapitalize="autocapitalize"
            :autocorrect="autocorrect"
            v-bind="$attrs"
            @input="change"
        />

        <!-- error -->
        <div v-if="error" class="lsn-form__error">
            {{ errMsg }}
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'LsnTextInput',

    props:
    {
        modelValue:
        {
            type:    [String, Number],
            default: null,
        },
        label:
        {
            type:    String,
            default: null,
        },
        placeholder:
        {
            type:    String,
            default: '',
        },
        error:
        {
            type:    [String, Array],
            default: null,
        },
        disabled:
        {
            type:    Boolean,
            default: false,
        },
        readonly:
        {
            type:    Boolean,
            default: false,
        },
        required:
        {
            type:    Boolean,
            default: false,
        },
        autocomplete:
        {
            type:    String,
            default: 'off',
        },
        autocapitalize:
        {
            type:    String,
            default: 'off',
        },
        autocorrect:
        {
            type:    String,
            default: 'off',
        },
    },

    emits: ['update:modelValue'],

    computed:
    {
        errMsg()
        {
            return Array.isArray(this.error) ? this.error[0] : this.error;
        },
    },

    methods:
    {
        change(event)
        {
            this.$emit('update:modelValue', event.target.value);
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.lsn-form__wrapper
{
    @apply relative p-4;
}

.lsn-form__label
{
    @apply block mb-2 pl-2 text-primary-600;
}

.lsn-form__input
{
    height: 48px;
    @apply px-3 rounded-lg w-full outline-primary-500 border-2 border-primary-600 tracking-wider text-center text-primary-900 text-lg;
}

.lsn-form__error
{
    @apply mt-1 text-red-600;
}

</style>
