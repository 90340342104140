<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-simple-layout>
        <template #default>

            <div id="consent-pdf-preview">
                <!-- pdf will come here -->
            </div>

            <div class="navigation-wrapper">

                <div class="flex items-center justify-evenly">

                    <div class="flex items-center justify-center relative self-stretch">
                        <div class="nav-link" @click="backToWelcome">
                            <svg-icon type="mdi" :path="mdiChevronLeft" size="60"></svg-icon>
                        </div>

                        <button v-if="hasSigned" class="absolute bottom-0 lsn-btn lsn-btn--primary-outline" @click="clear">
                            {{ $t('common.reset') }}
                        </button>
                        <button v-else class="absolute bottom-0 lsn-btn lsn-btn--disable">
                            {{ $t('common.reset') }}
                        </button>
                    </div>

                    <div class="signature-wrapper">
                        <div class="signature">
                            <v-perfect-signature ref="signaturePad" :stroke-options="strokeOptions" />
                        </div>
                    </div>

                    <div class="left-part">
                        <template v-if="hasSigned">
                            <div class="nav-link" @click="confirm">
                                <svg-icon type="mdi" :path="mdiCheck" size="60"></svg-icon>
                            </div>
                        </template>
                        <template v-else>
                            <div class="nav-link nav-link--disable">
                                <svg-icon type="mdi" :path="mdiCheck" size="60"></svg-icon>
                            </div>
                        </template>

                        <a class="download-link left-0" :href="getPdfUrl" download="download">
                            <lsn-icon iname="lsn-download"></lsn-icon>
                        </a>
                        <a class="download-link right-0" @click="showSideMailPreview = true">
                            <lsn-icon iname="lsn-email-outline"></lsn-icon>
                        </a>
                    </div>

                </div>

            </div>

        </template>
    </lsn-simple-layout>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Preview',
};

</script>

<script setup>

import PDFObject from 'pdfobject';
import VPerfectSignature from 'v-perfect-signature';
import { mdiChevronLeft, mdiCheck } from '@mdi/js';
import getStroke from 'perfect-freehand';
import axios from '@/axios';
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();
const isMounted = ref(false);
const signaturePad = ref(null);

const strokeOptions =  {
    size:       8,
    thinning:   0.75,
    smoothing:  0.5,
    streamline: 0.5,
};

onMounted(() =>
{
    fetchPreview();
    isMounted.value = true;
});

const hasSigned = computed(() =>
{
    if(!isMounted.value)
    {
        return false;
    }

    return !signaturePad.value.isEmpty();
});

function fetchPreview()
{
    PDFObject.embed(getPdfUrl(), '#consent-pdf-preview', {
        PDFJS_URL:     import.meta.env.VITE_WEB_LOCATION + '/pdfjs/web/viewer.html',
        forcePDFJS:    true,
        pdfOpenParams: {
            view: 'FitH',
        },
    });
}

function getPdfUrl()
{
    let url = import.meta.env.VITE_WEB_LOCATION + '/consent/preview/:patient_hid/:pdf_document_hid/:language/:practitioner_hid';

    url = url.replace(':patient_hid', route.params.patient_hid);
    url = url.replace(':pdf_document_hid', route.params.pdf_document_hid);
    url = url.replace(':language', route.params.language);
    url = url.replace(':practitioner_hid', route.params.practitioner_hid);

    return url;
}

function clear()
{
    signaturePad.value.clear();
}

function backToWelcome()
{
    router.go(-1);
}

function confirm()
{
    let path = '';

    for(let i in signaturePad.value.toData())
    {
        let cLinePoints = signaturePad.value.toData()[i];

        if(cLinePoints.length > 1)
        {
            let cLineStroke = getStroke(cLinePoints, strokeOptions);
            path += getSignatureAsSvg(cLineStroke);
        }
    }

    let svg = '<svg width="500" height="200" xmlns="http://www.w3.org/2000/svg"><path fill="black" d="' + path + '" /></svg>';

    let url = '/v1/consent/generate/:patient_hid/:pdf_document_hid/:language/:practitioner_hid';

    url = url.replace(':patient_hid', route.params.patient_hid);
    url = url.replace(':pdf_document_hid', route.params.pdf_document_hid);
    url = url.replace(':language', route.params.language);
    url = url.replace(':practitioner_hid', route.params.practitioner_hid);

    let data = {
        signature: svg,
    };

    axios.post(url, data,{ withCredentials: true }).then((response) =>
    {
        let file = response.data;

        router.push({
            name: 'confirmation',
            params:
            {
                file_hid: file.hid,
            },
        });
    });
}

function getSignatureAsSvg(stroke)
{
    if(!stroke.length)
    {
        return '';
    }

    const d = stroke.reduce((acc, [x0, y0], i, arr) =>
    {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);

        return acc;
    },
    ['M', ...stroke[0], 'Q']);

    d.push('Z');

    return d.join(' ');
}

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

#consent-pdf-preview
{
    height: calc(100vh - 244px);
    @apply absolute top-0 left-0 w-full bg-red-200;
}

.navigation-wrapper
{
    padding-top: 20px;
    padding-bottom: 20px;
    bottom: 0px;
    box-shadow: 0 -2px 6px -2px rgba(0, 0, 0, 0.1);
    @apply fixed w-full border-t border-primary-400 bg-primary-50;
}

.signature-wrapper
{
    height: 204px;
    @apply flex items-center justify-center bg-white;
}

.signature
{
    width: 504px;
    @apply h-full rounded-md border-2 border-primary-400;
}

.signature canvas
{
    width: 500px;
    height: 200px;
}

.nav-link
{
    height: 80px;
    width: 80px;
    @apply flex items-center justify-center -mt-10 rounded-full border-4 cursor-pointer  text-primary-400 border-primary-400 hover:bg-primary-100;
}

.lsn-btn
{
    height: 48px;
    @apply rounded-lg tracking-wider text-center text-lg border-0;
}

.lsn-btn--primary-outline
{
    @apply bg-white text-primary-600 border border-primary-600;
}

.lsn-btn--disable
{
    @apply bg-gray-50 text-gray-300 border border-gray-300;
}

.nav-link--disable
{
    @apply text-gray-400 border-gray-400 cursor-auto hover:bg-transparent;
}

.left-part
{
    width: 120px;
    @apply flex items-center justify-center relative self-stretch;
}

.download-link
{
    @apply absolute bottom-0 text-primary-500;
}

.download-link svg
{
    height: 40px;
    width: 40px;
}

@supports (-webkit-touch-callout: none)
{
    #consent-pdf-preview
    {
        height: calc(100vh - 244px - 87px);
    }
}

</style>
