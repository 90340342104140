/* ====================
 * Authentication Store
 * ====================
 */

import { defineStore } from 'pinia';
import axios from '@/axios';
// import IdleJs from 'idle-js';
// import moment from 'moment';
// import * as _ from 'lodash';
import Cookie from '@/utils/Cookie';
// import constants from '@/constants';

var regenerateAccessTokenIntervalID;
var idle;

export const useAuthenticationStore = defineStore('authentication', {

    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            isAuthenticated: null,
            refreshToken:    null,
        };
    },

    // ------------------------------------------------------------------------- ACTIONS

    actions:
    {
        check()
        {
            return axios.get('/v1/authentication/check', {
                withCredentials: true,
                credentials:     'include',
            });
        },

        unlock(pin)
        {
            return axios.post('/v1/authentication/unlock', { pin: pin }, {
                withCredentials: true,
                credentials:     'include',
            }).then((response) =>
            {
                // Add access token to HTTP header
                let accessToken = response.data.access_token;
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

                // Save refresh token
                let refreshToken = response.data.refresh_token;
                this.refreshToken = refreshToken;

                // Set application has "authenticated"
                this.isAuthenticated = true;

                // dispatch('startIdleWatching', user.session_duration), // Unauthenticate when application is idle.
                // dispatch('startAccessTokenRegenerationLoop', user.session_duration);

                return Promise.resolve(response);

            }).catch((error) =>
            {
                console.log('AUTHENTICATION CHECK: FAILED');

                return Promise.reject(error);
            });
        },

        lock()
        {
            dispatch('stopAccessTokenRegenerationLoop');
            commit('unauthenticate');
        },

        refreshAccessToken({ commit, dispatch, rootState })
        {
            let refreshToken = Cookie.getItem('refresh_token');

            return axios.post('/api/authentication/user/refresh', { refresh_token: refreshToken }).then((response) =>
            {
                let user = rootState.user.cUser;

                commit('setAccessToken', response.data.access_token);
                commit('setBearerHttpHeader', response.data.access_token);
                commit('authenticate');

                dispatch('startAccessTokenRegenerationLoop', user.session_duration);

                return Promise.resolve(response);

            }).catch((error) =>
            {
                console.log('AUTHENTICATION REFRESH ACCESS TOKEN: FAILED', error);

                return Promise.reject(error);
            });
        },

        startIdleWatching({ dispatch }, session_duration)
        {
            let idleTime = session_duration * 60 * 1000;

            if(idle)
            {
                idle.stop().reset();
            }

            idle = new IdleJs({
                idle:   idleTime,
                events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
                onIdle: function()
                {
                    console.log('Application is idle => unauthenticate');

                    dispatch('unauthenticate');
                },
                onActive: function()
                {
                    // callback function to be executed after back form idleness
                },
                onHide: function()
                {
                    // callback function to be executed when window become hidden
                },
                onShow: function()
                {
                    // callback function to be executed when window become visible
                },
                keepTracking: true, // set it to false if you want to be notified only on the first idleness change
                startAtIdle:  false, // set it to true if you want to start in the idle state
            });

            idle.start();
        },

        startAccessTokenRegenerationLoop({ dispatch }, session_duration)
        {
            // Session duration minus 1 minutes in milliseconds
            let refreshInterval = (session_duration - 1) * 60 * 1000;

            if(regenerateAccessTokenIntervalID)
            {
                clearInterval(regenerateAccessTokenIntervalID);
            }

            regenerateAccessTokenIntervalID = setInterval(() =>
            {
                dispatch('refreshAccessToken');
            },
            refreshInterval);
        },

        stopAccessTokenRegenerationLoop()
        {
            clearInterval(regenerateAccessTokenIntervalID);
        },

        //---------------------------------------------------------------------- HELPER

        authenticate(state)
        {
            state.isAuthenticated = true;
        },

        unauthenticate(state)
        {
            state.isAuthenticated = false;

            Cookie.removeItem('access_token', '/', constants.url.DONNA_ROOT_DOMAIN);
            Cookie.removeItem('refresh_token', '/', constants.url.DONNA_ROOT_DOMAIN);
        },
    },
});
