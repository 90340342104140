<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="lsn-form__wrapper">

        <!-- label -->
        <label v-if="label" class="lsn-form__label">
            {{ label }}
            <template v-if="required">
                &nbsp;*
            </template>
        </label>

        <div class="flex">

            <!-- day selector -->
            <select v-model.number="day" class="lsn-form__selector mr-3">
                <option value=""></option>
                <option v-for="index in 31" :key="index" :value="index">
                    {{ index }}
                </option>
            </select>

            <!-- month selector -->
            <select v-model.number="month" class="lsn-form__selector mr-3" :disabled="disabled">
                <option value=""></option>
                <option value="1">
                    janvier
                </option>
                <option value="2">
                    février
                </option>
                <option value="3">
                    mars
                </option>
                <option value="4">
                    avril
                </option>
                <option value="5">
                    mai
                </option>
                <option value="6">
                    juin
                </option>
                <option value="7">
                    juillet
                </option>
                <option value="8">
                    août
                </option>
                <option value="9">
                    septembre
                </option>
                <option value="10">
                    octobre
                </option>
                <option value="11">
                    novembre
                </option>
                <option value="12">
                    décembre
                </option>
            </select>

            <!-- year selector -->
            <select v-model.number="year" class="lsn-form__selector">
                <option></option>
                <option v-for="cYear in years" :key="cYear" :value="cYear">
                    {{ cYear }}
                </option>
            </select>

        </div>

        <!-- error -->
        <div v-if="error" class="lsn-form__error">
            {{ errMsg }}
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import moment from 'moment';

export default
{
    name: 'LsnDateInput',

    props:
    {
        modelValue:
        {
            type:    [String, Object],
            default: null,
        },
        label:
        {
            type:    String,
            default: null,
        },
        error:
        {
            type:    [String, Array],
            default: null,
        },
        disabled:
        {
            type:    Boolean,
            default: false,
        },
        required:
        {
            type:    Boolean,
            default: false,
        },
        inputFormat:
        {
            type:    String,
            default: 'DD.MM.YYYY',
        },
        outputFormat:
        {
            type:    String,
            default: 'DD.MM.YYYY',
        },
    },

    emits: ['update:modelValue'],

    data()
    {
        return {
            day:   null,
            month: null,
            year:  null,
        };
    },

    computed:
    {
        errMsg()
        {
            return Array.isArray(this.error) ? this.error[0] : this.error;
        },

        years()
        {
            let endYear = new Date().getFullYear();
            let startYear = endYear - 110;
            let years = [];

            for(let i = endYear; i > startYear; i -= 1)
            {
                years.push(i);
            }

            return years;
        },
    },

    watch:
    {
        modelValue:
        {
            immediate: true,
            handler(modelValue, old)
            {
                if(typeof modelValue === 'string')
                {
                    modelValue = moment(modelValue, this.inputFormat, true);
                }

                if(moment.isMoment(modelValue))
                {
                    this.day = parseInt(modelValue.format('D'));
                    this.month = parseInt(modelValue.format('M'));
                    this.year = parseInt(modelValue.format('YYYY'));
                }
            },
        },

        day()
        {
            this.setModelDate();
        },

        month()
        {
            this.setModelDate();
        },

        year()
        {
            this.setModelDate();
        },
    },

    methods:
    {
        setModelDate()
        {
            if(Number.isInteger(this.day) && Number.isInteger(this.month) && Number.isInteger(this.year))
            {
                // Month - 1 because months starts at 0 in momentjs.
                let date = moment().set('year', this.year).set('month', (this.month - 1)).set('date', this.day);

                this.$emit('update:modelValue', date.format(this.outputFormat));
            }
            else
            {
                this.$emit('update:modelValue', null);
            }
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.lsn-form__wrapper
{
    @apply relative p-4;
}

.lsn-form__label
{
    @apply block mb-2 pl-2 text-primary-600;
}

.lsn-form__selector
{
    height: 48px;
    background-repeat: no-repeat;
    background-color: white;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQUlEQVQoU2NkIBIwoqubs3j9/5TYQAxxFAGQIphGdMVwhciKsCkGK8SmCF0xhltw+Y10E4lyI8w6onyNrBhbOAIAkkIgC5kIYPcAAAAASUVORK5CYII=");
    background-position: right 0.5rem center;
    @apply appearance-none pl-3 pr-6 rounded-lg outline-primary-500 border-2 border-primary-600 tracking-wider text-center text-primary-900 text-lg;
}

.lsn-form__error
{
    @apply mt-1 text-red-600;
}

</style>
