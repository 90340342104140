<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div v-if="loading" class="">
        LOADING
    </div>

    <router-view v-else-if="isAuthenticated" v-slot="{ Component }">
        <component :is="Component" />
    </router-view>

    <pin v-else />

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Application',
};

</script>

<script setup>

import axios from '@/axios';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useAuthenticationStore } from '@/stores/authentication';
import Cookie from '@/utils/Cookie';
import Pin from './Pin';

const loading = ref(false);
const authenticationStore = useAuthenticationStore();
const { isAuthenticated } = storeToRefs(authenticationStore);

function initialize()
{
    // Fetch application initialization values here.
    return Promise.resolve(true);
}

watch(isAuthenticated, (isAuthenticated) =>
{
    if(isAuthenticated)
    {
        loading.value = true;

        initialize().then(() =>
        {
            loading.value = false;
        });
    }
});

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>



</style>
