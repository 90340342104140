/* ==============
 * Consents Store
 * ==============
 */

import { defineStore } from 'pinia';
import axios from '@/axios';

export const useConsentsStore = defineStore('consents', {

    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            consents: [],
        };
    },

    // ------------------------------------------------------------------------- ACTIONS

    actions:
    {
        fetchByPatient(patientHid)
        {
            const url = ('/v1/consent/patient/:patient_hid').replace(':patient_hid', patientHid);

            return axios.get(url, {
                withCredentials: true,
            }).then((response) =>
            {
                this.consents = response.data;
            });
        },
    },
});
