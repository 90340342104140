import axios from 'axios';

//------------------------------------------------------------------------------ CONFIGURATION

axios.defaults.baseURL = import.meta.env.VITE_API_LOCATION;

//------------------------------------------------------------------------------ INTERCEPTOR

axios.interceptors.response.use(
    response =>
    {
        if(response.data?.data)
        {
            response.data = response.data.data;
        }

        if(response.data?.meta)
        {
            response.meta = response.data.meta;
        }

        return response;
    },
    error =>
    {
        // If a request return a 412 => device is not authorized => redirect to Habile Authentication Application
        if(error.response.status == 412)
        {
            window.location.replace(import.meta.env.VITE_AUTHENTICATION_APP_URL);
        }

        if(error.response?.data)
        {
            error.response.meta = error.response.data.meta;
            error.response.errors = error.response.data.errors || error.response.data.message;
            delete error.response.data;

            if(import.meta.env.VITE_DEBUG_MODE)
            {
                console.log('--- Axios Errors', error.response.errors);
                console.log('--- Axios Meta', error.response.meta);
            }
        }

        return Promise.reject(error);
    }
);

//------------------------------------------------------------------------------

export default axios;
