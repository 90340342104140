/* ===================
 * PDF Documents Store
 * ===================
 */

import { defineStore } from 'pinia';
import axios from '@/axios';

export const usePdfDocumentsStore = defineStore('pdfDocuments', {

    // ------------------------------------------------------------------------- STATE

    state()
    {
        return {
            pdfDocuments: [],
        };
    },

    // ------------------------------------------------------------------------- ACTIONS

    actions:
    {
        fetch()
        {
            return axios.get('/v1/pdf-document', {
                withCredentials: true,
            }).then((response) =>
            {
                this.pdfDocuments = response.data;

                return Promise.resolve(response);
            });
        },
    },
});
