/**********
 * Routes *
 *********/

import ConsentForm from '@/views/ConsentForm';
import ConsentPreview from '@/views/ConsentPreview';
import ConsentView from '@/views/ConsentView';
import End from '@/views/End';
import PatientForm from '@/views/PatientForm';
import Welcome from '@/views/Welcome';

export default[

    // ------------------------------------------------------------------------- BASE

    {
        path:     '/consent/:lang([a-z]{2})',
        redirect: { name: 'welcome' },
        name:     'base',

        children:
        [
            // ----------------------------------------------------------------- Step 1 : Choose patient or create one

            {
                path:      'welcome',
                name:      'welcome',
                component: Welcome,
            },

            // ----------------------------------------------------------------- Step 2 : Choose consent form

            {
                path:      'consent/form/patient/:patient_hid?',
                name:      'consent-form',
                component: ConsentForm,
            },

            // ----------------------------------------------------------------- Step 3 : Create or edit patient form

            {
                path:      'consent/:pdf_document_hid/:language/:practitioner_hid/patient/form/:patient_hid?',
                name:      'patient-form',
                component: PatientForm,
            },

            // ----------------------------------------------------------------- Step 4 : Preview PDF consent with signature

            {
                path:      'consent/preview/:pdf_document_hid/:language/:practitioner_hid/patient/:patient_hid',
                name:      'consent-preview',
                component: ConsentPreview,
            },

            // ----------------------------------------------------------------- Step 5 : View signed PDF consent (+ download and send by e-mail)

            {
                path:      'consent/view/:file_id',
                name:      'consent-view',
                component: ConsentView,
            },

            // ----------------------------------------------------------------- Step 6 : Finalization

            {
                path:      'end/:file_hid',
                name:      'end',
                component: End,
            },
        ],
    },

    // --------------------------------------------------------------------------------
    // ----------------------------------------------------------------- DEFAULT ROUTES
    // --------------------------------------------------------------------------------

    {
        path:     '/',
        redirect: { name: 'welcome' },
    },

    {
        path:     '/*',
        redirect: { name: 'welcome' },
    },
];
